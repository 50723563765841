import axios, { CreateAxiosDefaults } from "axios";
import store from "@/store";

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
} as CreateAxiosDefaults);

baseApi.interceptors.request.use(req => {
  req.headers.Authorization = `Bearer ${store.getters.getToken}`;
  return req;
});

baseApi.interceptors.response.use(
  res => res,
  e => {
    if (e.response && e.response.status === 401) {
      store.dispatch("logout");
    }
  }
);

const infotekaApi = axios.create({
  baseURL: process.env.VUE_APP_INFOTEKA_URL
} as CreateAxiosDefaults);

export { infotekaApi };

export default baseApi;
