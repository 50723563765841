




































import { NavigationRouteItemInterface } from "@/models/navigation/NavigationRouteItem.interface";
import { defineComponent } from "vue";
import { CurrentUserInterface } from "@/models/store/CurrentUser.interface";

export default defineComponent({
  name: "App",
  computed: {
    user(): CurrentUserInterface | null {
      return this.$store.getters.user;
    },
    routes(): NavigationRouteItemInterface[] {
      return this.user?.email === "support@yandex.ru"
        ? [
            {
              title: "Регистрация пользователей",
              route: "/register",
              icon: "mdi-account-plus"
            }
          ]
        : [
            {
              title: "Заказ FTL",
              route: "/order-ftl",
              icon: "mdi-package"
            },
            {
              title: "Загрузка заказов",
              route: "/import",
              icon: "mdi-file-excel"
            }
          ];
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
});
