import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vueInputAutoWidth from "./plugins/vueInputAutoWidth";
import "./plugins/vuelidate";

Vue.config.productionTip = false;

Vue.use(vueInputAutoWidth);

Vue.mixin({
  computed: {
    isProduction() {
      return process.env.VUE_APP_MODE === "production";
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  watch: {
    "$store.getters.isAuthenticated"(isAuthenticated: boolean): void {
      if (isAuthenticated) return;

      this.$router.push("/login");
    }
  },
  render: h => h(App)
}).$mount("#app");
