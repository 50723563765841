import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import { PersonType } from "@/models/person/Person.interface";

export default class PersonItemModel implements PersonItemInterface {
  firstName: string;
  fullName: string;
  id: number;
  inn: string;
  lastName: string;
  middleName: string;
  name: string;
  address: string;
  type: PersonType;
  countryCode: string;

  constructor() {
    this.firstName = "";
    this.fullName = "";
    this.id = 0;
    this.inn = "";
    this.lastName = "";
    this.middleName = "";
    this.name = "";
    this.type = PersonType.COMPANY;
    this.address = "";
    this.countryCode = "RUS";
  }

  clearData() {
    this.firstName = "";
    this.fullName = "";
    this.id = 0;
    this.inn = "";
    this.lastName = "";
    this.middleName = "";
    this.name = "";
    this.type = PersonType.COMPANY;
    this.address = "";
  }

  updateData(data: PersonItemInterface) {
    this.clearData();
    this.id = data.id;
    this.countryCode = data.countryCode;

    if (data.type === PersonType.COMPANY) {
      this.type = PersonType.COMPANY;
      this.name = data.name;
      this.inn = data.inn;
      this.address = data.address;
      return;
    }

    this.type = data.type;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.middleName = data.middleName;
    this.fullName = data.fullName;
    if (data.type === PersonType.INDIVIDUAL) return;

    this.inn = data.inn;
  }

  getFullName(): string {
    return (
      this.fullName || `${this.lastName} ${this.firstName} ${this.middleName}`
    );
  }
}
