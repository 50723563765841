import { NavigationGuardNext, Route } from "vue-router";
import store from "@/store";

export async function checkAuth(
  this: Vue,
  to: Route,
  from: Route,
  next: NavigationGuardNext
) {
  if (store.getters.isAuthenticated) {
    next();
    return true;
  }

  if (!localStorage.getItem("token")?.length) {
    next("/login");
    return false;
  }

  store.commit("SET_TOKEN", localStorage.getItem("token"));
  store.commit("UPDATE_AUTH", true);
  store
    .dispatch("updateToken")
    .then(() => {
      next();
    })
    .catch(() => {
      next("/login");
    });
}
