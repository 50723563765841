import Vue from "vue";
import Vuex from "vuex";
import { UserStateInterface } from "@/models/store/UserState.interface";
import {
  AuthEntityInterface,
  AuthResponseInterface
} from "@/models/api/AuthResponse.interface";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import { getUsers, login, updateToken } from "@/api/user";
import { AuthRequestInterface } from "@/models/auth/AuthRequest.interface";
import {
  UserFormInterface,
  UserInterface
} from "@/models/store/User.interface";
import { pick } from "lodash/fp";
import { CurrentUserInterface } from "@/models/store/CurrentUser.interface";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { getOrganization } from "@/api/autocomplete";
import { AutocompleteStateInterface } from "@/models/store/AutocompleteState.interface";
import { OrganizationInterface } from "@/models/store/Organization.interface";
import { parseDateString } from "@/utils/dateUtils";
import PersonService from "@/models/person/Person.service";

Vue.use(Vuex);

const state: UserStateInterface = {
  isAuthenticated: false,
  token: "",
  processing: false,
  authError: false,
  expire: "",
  updateTimer: 0,
  ownerCountry: "",
  user: null,
  users: []
};

const autocompleteState: AutocompleteStateInterface = {
  customerList: [],
  recipientList: [],
  senderList: []
};

export default new Vuex.Store({
  state,
  mutations: {
    UPDATE_AUTH(state: UserStateInterface, isAuth: boolean) {
      state.isAuthenticated = isAuth;
      if (!isAuth) {
        state.token = "";
        state.expire = "";
        localStorage.setItem("token", "");
        localStorage.setItem("expire", "");
      }
    },
    SET_TOKEN(state, token: string) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    SET_EXPIRE(state, expire: string) {
      state.expire = expire;
      localStorage.setItem("expire", expire);
    },
    SET_PROCESSING(state, isProcessing: boolean) {
      state.processing = isProcessing;
    },
    SET_AUTH_ERROR(state, isAuthError: boolean) {
      state.authError = isAuthError;
    },
    SET_UPDATE_TIMER(state, timer: number) {
      state.updateTimer = timer;
    },
    SET_USERS(state, users: UserFormInterface[]) {
      state.users = users;
    },
    SET_USER(state, user: CurrentUserInterface) {
      state.user = user;
    },
    SET_OWNER_COUNTRY(state, ownerCountry: string) {
      state.ownerCountry = ownerCountry;
    }
  },
  getters: {
    getToken: state => state.token,
    isAuthenticated: state => state.isAuthenticated,
    updateTimer: state => state.updateTimer,
    user: state => state.user,
    users: state => state.users,
    getOwnerCountry: state => state.ownerCountry
  },
  actions: {
    async login(
      { commit, dispatch },
      authRequest: AuthRequestInterface
    ): Promise<void> {
      commit("SET_AUTH_ERROR", false);
      commit("SET_PROCESSING", true);

      try {
        const res: AuthResponseInterface | ErrorResponseInterface = await login(
          authRequest
        );

        if (!res.isSuccess) {
          commit("SET_TOKEN", "");
          commit("UPDATE_AUTH", false);
          commit("SET_EXPIRE", "");
          throw new Error(res.message);
        }

        commit("SET_TOKEN", res.entity.token);
        commit("SET_EXPIRE", res.entity.expire);
        commit(
          "SET_USER",
          pick(["name", "email"] as (keyof AuthEntityInterface)[], res.entity)
        );
        commit("UPDATE_AUTH", true);
        dispatch("autoUpdate");
      } finally {
        commit("SET_PROCESSING", false);
      }
    },
    async updateToken({ commit, dispatch, getters }): Promise<void> {
      commit("SET_AUTH_ERROR", false);
      commit("SET_PROCESSING", true);

      try {
        const res:
          | AuthResponseInterface
          | ErrorResponseInterface = await updateToken(getters.getToken);

        if (!res.isSuccess) {
          commit("SET_TOKEN", "");
          commit("SET_EXPIRE", "");
          commit("SET_AUTH_ERROR", true);
          commit("UPDATE_AUTH", false);
          throw new Error(res.message);
        }

        commit("SET_TOKEN", res.entity.token);
        commit("SET_EXPIRE", res.entity.expire);
        commit(
          "SET_USER",
          pick(["name", "email"] as (keyof AuthEntityInterface)[], res.entity)
        );
        dispatch("autoUpdate");
      } catch (e) {
        commit("UPDATE_AUTH", false);
        throw e;
      } finally {
        commit("SET_PROCESSING", false);
      }
    },
    autoUpdate({ commit, dispatch, getters }) {
      const timer: number = setTimeout(() => {
        dispatch("updateToken", getters.getToken).catch(() => {
          commit("UPDATE_AUTH", false);
          commit("SET_AUTH_ERROR", true);
        });
      }, 50 * 60 * 1000 /* 50 минут */);
      commit("SET_UPDATE_TIMER", timer);
    },
    async logout({ commit }) {
      commit("UPDATE_AUTH", false);
    },
    async getUsers({ commit }) {
      const users = (await getUsers()).map(
        (user: UserInterface): UserFormInterface => {
          return {
            ...user,
            creationDate: +(parseDateString(user.creationDate) ?? 0),
            isLoading: false
          } as UserFormInterface;
        }
      );

      commit("SET_USERS", users);
    }
  },
  modules: {
    autocomplete: {
      namespaced: true,
      state: autocompleteState,
      getters: {
        customerList(state) {
          return state.customerList;
        },
        senderList(state) {
          return state.senderList;
        },
        recipientList(state) {
          return state.recipientList;
        }
      },
      actions: {
        async fetchOrganization(
          { commit },
          {
            type,
            parentId = ""
          }: { type: OrganizationTypeEnum; parentId: string }
        ) {
          if (
            (type === OrganizationTypeEnum.Sender ||
              type === OrganizationTypeEnum.Recipient) &&
            !parentId
          ) {
            return;
          }

          const res = await getOrganization(type, parentId);

          if (!res.isSuccess) return;

          switch (type) {
            case OrganizationTypeEnum.Customer:
              commit("UPDATE_ORGANIZATION", {
                organization: "customerList",
                data: res.entity
              });
              break;
            case OrganizationTypeEnum.Sender:
              commit("UPDATE_ORGANIZATION", {
                organization: "senderList",
                data: res.entity
              });
              break;
            case OrganizationTypeEnum.Recipient:
              commit("UPDATE_ORGANIZATION", {
                organization: "recipientList",
                data: res.entity
              });
          }
        }
      },
      mutations: {
        UPDATE_ORGANIZATION(
          state: AutocompleteStateInterface,
          {
            organization,
            data
          }: {
            organization: "customerList" | "senderList" | "recipientList";
            data: OrganizationInterface[];
          }
        ) {
          state[organization] = data.map(
            PersonService.createPersonItemFromOrganization
          );
        }
      }
    }
  }
});
