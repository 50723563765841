import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { checkAuth } from "@/router/checkAuth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/order-ftl"
  },
  {
    path: "/order-ftl",
    name: "OrderFtl",
    component: () => import("../views/OrderFtl.vue"),
    beforeEnter: checkAuth
  },
  {
    path: "/import",
    name: "Import",
    component: () => import("../views/Import.vue"),
    beforeEnter: checkAuth
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    beforeEnter: checkAuth
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
