import {
  PersonCompanyInterface,
  PersonEntrepreneurInterface,
  PersonIndividualInterface,
  PersonType
} from "@/models/person/Person.interface";
import { getOrganizationByInn } from "@/api/dadata";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import PersonItemModel from "@/models/person/PersonItem.model";
import returnSplitName from "@/mixins/returnSplitName";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import store from "@/store";
import {
  OrganizationCompanyInterface,
  OrganizationEntrepreneurInterface,
  OrganizationInterface
} from "@/models/store/Organization.interface";

export default class PersonService {
  static async searchPersonByInn(
    inn: string
  ): Promise<PersonItemInterface | null> {
    if (!/^\d{10,12}$/.test(inn)) return null;

    const organization = await getOrganizationByInn(inn);

    if (!organization) return null;

    if (organization.type === PersonType.COMPANY) {
      const searchedPerson = new PersonItemModel();
      searchedPerson.name = organization.name.full;
      searchedPerson.inn = organization.inn;
      searchedPerson.type = organization.type;
      searchedPerson.address = organization.address.value;
      return searchedPerson;
    }

    const [lastName, firstName, middleName] = organization.name.full.split(" ");

    const searchedPerson = new PersonItemModel();
    searchedPerson.inn = organization.inn;
    searchedPerson.type = organization.type;
    searchedPerson.fullName = organization.name.full;
    searchedPerson.lastName = lastName;
    searchedPerson.firstName = firstName;
    searchedPerson.middleName = middleName;

    return searchedPerson;
  }

  static async setPersonInn(person: PersonItemInterface, inn: string) {
    person.inn = inn;

    const searchedPerson = await PersonService.searchPersonByInn(inn);

    if (searchedPerson === null) return;

    person.updateData(searchedPerson);
  }

  static async setPersonCountryCode(
    person: PersonItemInterface,
    countryCode: string
  ) {
    person.countryCode = countryCode;
  }

  static async changePersonType(person: PersonItemInterface, type: PersonType) {
    person.clearData();

    person.type = type;
  }

  static async updatePerson(
    person: PersonItemInterface,
    updData: PersonItemInterface,
    organizationType: OrganizationTypeEnum
  ) {
    person.updateData(updData);

    if (organizationType !== OrganizationTypeEnum.Customer) return;

    await store.dispatch("autocomplete/fetchOrganization", {
      type: OrganizationTypeEnum.Sender,
      parentId: person.id
    });
    await store.dispatch("autocomplete/fetchOrganization", {
      type: OrganizationTypeEnum.Recipient,
      parentId: person.id
    });
  }

  static async returnPersonWithSplitName(
    person: PersonItemInterface
  ): Promise<
    | PersonCompanyInterface
    | PersonEntrepreneurInterface
    | PersonIndividualInterface
  > {
    if (person.type === PersonType.COMPANY)
      return {
        ...person,
        countryCode: person.countryCode
      };
    const data = await returnSplitName(person.fullName);

    return {
      ...person,
      ...data,
      countryCode: person.countryCode
    };
  }

  static createPersonItemFromOrganization(
    org: OrganizationInterface
  ): PersonItemInterface {
    const person = new PersonItemModel();

    switch (org.type) {
      case PersonType.COMPANY: {
        const orgCompany = org as OrganizationCompanyInterface;
        person.inn = orgCompany.inn;
        person.name = orgCompany.name;
        person.type = orgCompany.type;
        person.address = orgCompany.address;
        break;
      }
      case PersonType.ENTREPRENEUR: {
        const orgEntrepreneur = org as OrganizationEntrepreneurInterface;
        person.inn = orgEntrepreneur.inn;
        person.type = orgEntrepreneur.type;
        person.lastName = orgEntrepreneur.lastName;
        person.firstName = orgEntrepreneur.firstName;
        person.middleName = orgEntrepreneur.middleName;
        person.fullName = `${orgEntrepreneur.lastName} ${orgEntrepreneur.firstName} ${orgEntrepreneur.middleName}`;
        break;
      }
    }

    person.id = org.id;
    person.countryCode = org.countryCode ?? "RUS";

    return person;
  }
}
