export default async function returnSplitName(
  fullName: string
): Promise<{ lastName: string; firstName: string; middleName: string }> {
  const splitFullName = fullName.split(" ");

  switch (splitFullName.length) {
    case 0:
      return {
        firstName: "",
        lastName: "",
        middleName: ""
      };
    case 1:
      return {
        firstName: splitFullName[0],
        lastName: "",
        middleName: ""
      };
    case 2:
      return {
        lastName: splitFullName[0],
        firstName: splitFullName[1],
        middleName: ""
      };
    default:
      return {
        lastName: splitFullName[0],
        firstName: splitFullName[1],
        middleName: splitFullName.slice(2).join(" ")
      };
  }
}
