import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import baseApi from "@/api/baseApi";
import { DataResponseInterface } from "@/models/api/DataResponse.interface";
import { AddressTypeEnum } from "@/models/autocomplete/AddressType.enum";
import { OrganizationInterface } from "@/models/store/Organization.interface";
import { AddressInterface } from "@/models/order/Address.interface";
import { ContactInterface } from "@/models/order/Contact.interface";

export async function getOrganization(
  organization: OrganizationTypeEnum,
  parentId = ""
): Promise<
  DataResponseInterface<OrganizationInterface[]> | ErrorResponseInterface
> {
  return (
    await baseApi.get(
      `/info/organization?type=${organization}&parentId=${parentId}`
    )
  ).data;
}

export async function getAddress(
  orgId: number,
  addressType: AddressTypeEnum
): Promise<DataResponseInterface<AddressInterface[]> | ErrorResponseInterface> {
  return (await baseApi.get(`/info/address?orgId=${orgId}&type=${addressType}`))
    .data;
}

export async function getContacts(
  orgId: number
): Promise<DataResponseInterface<ContactInterface[]> | ErrorResponseInterface> {
  return (await baseApi.get(`/info/contact?orgId=${orgId}`)).data;
}
